import React, { useRef, useEffect } from "react";
import { useDisableBodyScroll } from "../commonhooks";
import AppMessages from "../../utilities/Messages";

export default function SidePanel(props) {
  const {
    children,
    title,
    onClose,
    style,
    headerStyles,
    styleforcontent,
    hideCloseAction,
    showAssociation,
    from,
    noAssociation,
    showTagsAssociation,
    nonScheduleTags,
    deleteSelTags,
    deleteSelGroup,
    backToDelete,
    titleClassName,
    publishedWorkflows,
    deleteAssWebhook,
    removeMarginTop,
    moveFile,
    selectedFolder,
    currentFolderPath,
    currentFileName,
    mmClientId,
    sourcePath,
    savePollSection,
    selectedFiles
  } = props;
  const ref = useRef();

  useDisableBodyScroll();

  useEffect(() => {
    // Animate the panel to the left
    ref.current.classList.remove("slide-left");
  }, []);

  return (
    <div>
      <div
        className={from === "pollCampaignSettings" ? "" : "sliderContBoxOverlay"}
        style={sourcePath == "ImportList" ? style : {}}
      />
      <div ref={ref} className="sliderContBox slide-left" style={style}>
        {/* {props.subHeader || ""} */}
        <div className="scb-header" style={headerStyles}>
          <label
            className={titleClassName ? titleClassName : ""}
            style={
              removeMarginTop
                ? { marginTop: "0px" }
                : from == "moveFilePanel"
                ? { fontWeight: "bold", display: "flex" }
                : {}
            }
          >
            {title}
            {from == "moveFilePanel" ? (
              <p
                style={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  width: "360px",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
                title={sourcePath.replace("BEE-Editor/Images/", " ").replace("Client" + mmClientId, "Home")}
              >
                &nbsp;-&nbsp;
                {sourcePath.replace("BEE-Editor/Images/", " ").replace("Client" + mmClientId, "Home")}
              </p>
            ) : null}
          </label>
          {hideCloseAction === true || showAssociation ? null : (
            <span className="closeBtn" onClick={onClose}>
              <i className="fa fa-times" />
            </span>
          )}
        </div>
        {from === "pollCampaignSettings" ? (
          <div style={{ position: "relative", zIndex: "1" }}>
            <div
              className="box-header d-flex align-items-center justify-content-end"
              style={{ backgroundColor: "#eaeaea", height: "50px" }}
            >
              <div className="row">
                <div className="col-sm bh-left"></div>
                <div className="col-sm bh-right">
                  <button type="button" className="btn btn-blue btn-sm mr-2" onClick={savePollSection}>
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {from === "Tag Management" ? (
          <div style={{ position: "relative", zIndex: "1", padding: "15px" }}>
            <div className="box-header">
              {!showAssociation ? (
                <div className="row">
                  <div className="col-sm bh-left"></div>
                  <div className="col-sm bh-right">
                    {noAssociation ? (
                      <button className="btn btn-primary btn-md btn-blue disabled" role="button">
                        Show Associations
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-md btn-blue"
                        role="button"
                        onClick={() => {
                          showTagsAssociation();
                        }}
                      >
                        Show Associations
                      </button>
                    )}

                    {nonScheduleTags && nonScheduleTags.length > 0 ? (
                      <button
                        role="button"
                        name={
                          !title == "Delete Tags" &&
                          "Delete_" + nonScheduleTags[0].grpCat + "_" + nonScheduleTags[0].activeGroup
                        }
                        style={{
                          backgroundColor: "#c14343",
                          fontWeight: "700",
                          fontSize: "13px",
                          borderRadius: "2px",
                          color: "#fff",
                          height: "36px",
                          outline: "none",
                          border: "none",
                          cursor: "pointer",
                          padding: "6px 18px",
                          marginLeft: "15px"
                        }}
                        onClick={(event) => {
                          title === "Delete Tags" ? deleteSelTags() : deleteSelGroup(event, true);
                        }}
                      >
                        {title == "Delete Tags" ? "Delete" : "Deactivate"}
                      </button>
                    ) : (
                      <button
                        role="button"
                        style={{
                          backgroundColor: "#c14343",
                          fontWeight: "700",
                          fontSize: "13px",
                          borderRadius: "2px",
                          color: "#fff",
                          height: "36px",
                          outline: "none",
                          border: "none",
                          padding: "6px 18px",
                          marginLeft: "15px",
                          opacity: 0.3
                        }}
                        className="btn btn-primary disabled"
                      >
                        {title == "Delete Tags" ? "Delete" : "Deactivate"}
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-sm bh-left"></div>
                  <div className="col-sm" style={{ textAlign: "center" }}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#6c757d",
                        marginTop: "6px",
                        marginBottom: "6px"
                      }}
                    >
                      {title === "Delete Tags" ? "TAG" : "GROUP"} ASSOCIATIONS
                    </p>
                  </div>
                  <div className="col-sm bh-right">
                    <a onClick={() => backToDelete()} href>
                      <img src="/images/icon-close.svg" alt="close" />
                    </a>
                  </div>
                </div>
              )}
              {/* <hr style={{ borderTop: "1px solid #bbb", marginRight: "8px" }} /> */}
            </div>
          </div>
        ) : (
          <div>
            {from == "fromWbhook" ? (
              <div style={{ position: "relative", zIndex: "1", padding: "15px" }}>
                <div className="box-header">
                  <div className="row">
                    <div className="col-sm bh-left"></div>
                    <div className="col-sm bh-right">
                      <button
                        role="button"
                        style={{
                          backgroundColor: "#c14343",
                          fontWeight: "700",
                          fontSize: "13px",
                          borderRadius: "2px",
                          color: "#fff",
                          height: "36px",
                          outline: "none",
                          border: "none",
                          cursor: `${publishedWorkflows && publishedWorkflows.length > 0 ? "not-allowed" : "pointer"}`,
                          padding: "6px 18px",
                          marginLeft: "15px"
                        }}
                        title={
                          publishedWorkflows && publishedWorkflows.length > 0
                            ? "Webhook cannot be deleted as it is used in the below Published Workflows"
                            : ""
                        }
                        className={
                          publishedWorkflows && publishedWorkflows.length > 0
                            ? "btn btn-primary disabled"
                            : "btn btn-primary"
                        }
                        onClick={publishedWorkflows && publishedWorkflows.length > 0 ? "" : () => deleteAssWebhook()}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
        {from == "moveFilePanel" ? (
          <div style={{ position: "relative", zIndex: "1", padding: "15px" }}>
            <div className="row" style={{ display: "flex", flexWrap: "nowrap" }}>
              <div className="col-sm-12 col-md-10 col-lg-10 bh-left">
                <p className="selectedFileNamesList">Selected File Names : </p>
                <ul className="selectedFileNamesParatag">{selectedFiles.join(", ")}</ul>
              </div>
              <div className="col-sm-12 col-md-2 col-lg-2 bh-right">
                {!selectedFolder || sourcePath == selectedFolder ? (
                  <button
                    title={AppMessages.fileExplorer.fileMoveNotAllowed}
                    className="btn btn-primary btn-md btn-blue disabled cursor-not-allowed"
                  >
                    Move
                  </button>
                ) : (
                  <button
                    title={"Move file"}
                    className="btn btn-primary btn-md btn-blue"
                    onClick={() => moveFile(selectedFolder, currentFileName)}
                  >
                    Move
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={`scb-cont  ${from == "AISubject" ? "" : "custom-scroll"}`}
          style={
            from === "Tag Management"
              ? { position: "absolute", top: "117px", padding: "0px" }
              : from == "fromWbhook"
              ? { position: "absolute", top: "100px", padding: "15px" }
              : from == "pollCampaignSettings"
              ? { position: "absolute", top: "90px", padding: "0px" }
              : styleforcontent
          }
          id={from == "moveFilePanel" ? "" : "manageSender"}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
